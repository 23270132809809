import React from "react";
import AboutNav from "./AboutNav.tsx";
import ProductNav from "./ProductNav.tsx";
import SolutionsNav from "./SolutionsNav.tsx";
import ResourcesNav from "./ResourcesNav.tsx";
import { Button } from "@/components/ui/button";
import hamburgerIcon from "../../assets/images/svg/hamburger.svg";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export default ({data}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="plain" className="p-3 pt-4">
          <img src={hamburgerIcon.src} />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="bg-white w-screen rounded-none p-0">
        <div className="overflow-scroll max-h-screen">
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
              <AccordionTrigger className="text-base font-bold text-black hover:text-link mb-0">
                Product
              </AccordionTrigger>
              <AccordionContent>
                <ProductNav data={data} />
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger className="text-base font-bold text-black hover:text-link mb-0">
                Solutions
              </AccordionTrigger>
              <AccordionContent>
                <SolutionsNav data={data} />
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger className="text-base font-bold text-black hover:text-link mb-0">
                Resources
              </AccordionTrigger>
              <AccordionContent>
                <ResourcesNav data={data} />
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-4">
              <AccordionTrigger className="text-base font-bold text-black hover:text-link mb-0">
                About
              </AccordionTrigger>
              <AccordionContent>
                <AboutNav data={data} />
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-5">
              <a
                href="/pricing"
                className="flex flex-1 items-center justify-between py-4 text-base font-bold font-opensans text-black hover:text-link mb-0"
              >
                Pricing
              </a>
            </AccordionItem>

            <AccordionItem className="border-none" value="item-7">
              <div className="flex py-6">
                <Button
                  size="small"
                  url="/book-a-demo"
                  className="w-full text-center"
                >
                  Book A Demo
                </Button>
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      </PopoverContent>
    </Popover>
  );
};
